import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link as GatsbyLink} from 'gatsby';
import {PrimaryButton, SecondaryButton} from '@nib-components/button';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import {SkipLink, Target} from '@nib-components/skip-link';
import {Section, Stack, Inline, breakpoint} from '@nib/layout';
import Tag from '@nib/tag';
import {Footer, Layout} from '@nib/gatsby-theme-mesh-docs';
import * as Icons from '@nib/icons';
import {Item} from '../components/icons/IconLibrary';
import {ICON_ASSETS_BASEURL} from '../constants';
import Header from '../components/header/Header';
import Banner from '../components/banner';

const GridLayout = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-rows: 3.5rem 1fr auto;
  grid-template-columns: 1fr;
`;

// const GridLayout = styled.div`
//   min-height: 100vh;
//   display: grid;
//   grid-template-areas:
//     'header'
//     'main'
//     'footer';
//   grid-template-rows: 3.5rem 1fr auto;
//   grid-template-columns: 1fr;

//   ${breakpoint('lg')`
//     grid-template-areas:
//       "header header"
//       "sidebar main"
//       "sidebar footer";
//     grid-template-columns: 15rem 1fr;
//   `};
// `;

const Main = styled.main`
  grid-area: main;
`;

const Box = styled(Item)`
  position: relative;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${breakpoint('lg')`
    min-height: 20rem;
  `}
`;

const PositionedTag = styled(Tag)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

const IconPage = ({pageContext}) => {
  const {name, title, category, usage, type} = pageContext;
  const Icon = Object.values(Icons).filter(x => x.displayName === name)[0];
  return (
    <Layout bannerContent={<Banner />}>
      <SkipLink link="#content" text="Skip to content" />
      <GridLayout>
        <Header />
        <Target id="content" component={Main}>
          <Section>
            <Stack space={6}>
              <Heading size={{xs: 2, sm: 1}} component="h1">
                {title}
              </Heading>

              <Box>
                <PositionedTag type="info">{category}</PositionedTag>
                <Icon size={type === 'System' ? 'md' : 'xxl'} />
              </Box>

              <Inline space={4} verticalAlign="center">
                <PrimaryButton href={`${ICON_ASSETS_BASEURL}/${type.toLowerCase()}/${title}.svg`} download={`${name}.svg`}>
                  Download this icon as SVG
                </PrimaryButton>

                <SecondaryButton href={`${ICON_ASSETS_BASEURL}/${type.toLowerCase()}/${title}.png`} download={`${name}.png`}>
                  Download this icon as PNG
                </SecondaryButton>
              </Inline>

              {usage && (
                <>
                  <Heading size={4} component="h2">
                    When to use
                  </Heading>
                  <ul>
                    {usage.map((bullet, index) => (
                      <li key={index}>{bullet}</li>
                    ))}
                  </ul>
                </>
              )}
              <Link component={GatsbyLink} to={`/foundations/iconography/${type.toLowerCase()}-icons/`}>
                <Icons.ChevronLeftSystemIcon size="xs" />
                &nbsp;
                <span>Back to {type.toLowerCase()} icons</span>
              </Link>
            </Stack>
          </Section>
        </Target>
        <Footer />
      </GridLayout>
    </Layout>
  );
};

IconPage.propTypes = {
  pageContext: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    category: PropTypes.string,
    usage: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.oneOf(['System', 'Graphic'])
  })
};

export default IconPage;
